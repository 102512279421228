import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import lodash from "lodash";
import { Animal } from "../animal";
import { Batch } from "../batch";
import { DataManager } from "../data-manager";
import tagCapture from "./modules/tag-capture";
import config from "./modules/config";
import ColourInfo from "@/colour-info";
import * as packageJson from "../../package.json";
import popup from "./modules/popup";
import { configData } from "../config";

let isSendingAnimals = false;

class FeedlotInfo {
  Name: string;
  GCP: string;
  M1: number;
  M2: number;
  M3: number;
  M4: number;
  M5: number;
  M6: number;
  ColorMode: number;
}

class RootState {
  //dbInitialized: boolean = false;
  currentBatch: Batch = null;
  //selectedTagType: "plain" | "allflex" | "dimssa" = null;
  feedlotInfo: [FeedlotInfo] = null;
  colourInfo: [ColourInfo] = null;
  sgln: string = null;
  massCaptureEnabled: boolean = true;
  version: string = packageJson.version;
  allflexEnabled: boolean = true;
  hardwareAddress: string = "hsl.gigalot.systems"; //127.0.0.1 - local  testing
  orcaAddress: string = "orca.gigalot.systems";
  lightDarkMode: "light" | "dark" = "dark";
  isSingleInstance: boolean = true;
  connectedToProxy: boolean = false;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage
  // reducer: (state) => ({ navigation: state.navigation }), //only save navigation module
  // filter: (mutation) => mutation.type == 'addNavItem'
  //modules: ["countModule", "appSettingsModule"]
  //modules: ["tagCapture"]
});

function getTagFeedlot(sgtin: string, feedlotsInfo: FeedlotInfo[]) {
  if (!sgtin) {
    //TODO: better sgtin checks
    return undefined;
  }
  let gcp = sgtin.slice(23, 23 + 10);
  let feedlotInfo: any = feedlotsInfo.find((fi: FeedlotInfo) => fi.GCP === gcp);
  return feedlotInfo;
}

function getTagColour(sgtin: string, feedlotsInfo: FeedlotInfo[], coloursInfo: ColourInfo[]) {
  let gcp = sgtin.slice(23, 23 + 10);

  let feedlotInfo: any = feedlotsInfo.find((fi: FeedlotInfo) => fi.GCP === gcp);

  if (feedlotInfo) {
    let colourMode = feedlotInfo.ColorMode;

    if (colourMode === 0) {
      //multi colour
      //let colourCode = sgtin[36];

      let colourCodeCode = sgtin[36];

      let colourCode = feedlotInfo["M" + colourCodeCode];

      let colourInfo = coloursInfo.find((ci: ColourInfo) => ci.Number === colourCode);
      if (colourInfo) {
        // let colourAbbr = colourInfo.Abbr;
        // let lastFive = sgtin.slice(-5);
        // //let hundredThousandDigit =
        // o.Eartag = colourAbbr + lastFive;
        // console.log("multi colour");
        // console.log("o.Eartag: " + o.Eartag);
        return colourInfo;
      } else {
        console.log("no colour info found for colour number " + colourCode);
      }
    } else if (colourMode === 1) {
      //single colour
      let colourCode = feedlotInfo.M1; //same as M2, M3, ... , M6
      let colourInfo = coloursInfo.find((ci: ColourInfo) => ci.Number === colourCode);
      if (colourInfo) {
        return colourInfo;
        // let colourAbbr = colourInfo.Abbr;
        // let lastFive = sgtin.slice(-5);
        // let hundredThousandDigit = sgtin[36]
        // o.Eartag = colourAbbr + hundredThousandDigit + lastFive;
        // console.log("single colour");
        // console.log("o.Eartag: " + o.Eartag);
      } else {
        console.log("no colour info found for colour number " + colourCode);
      }
    } //TODO: What about colourMode === 2 ? (See feedlot called Suceco)
  } else {
    console.log("no feedlot info found for gcp " + gcp);
  }
}

function sendAnimalsToHardwareServer(context: any): void {
  // if (isSendingAnimals)
  //   return;

  // if (state.animal === null) {
  //   return;
  // }

  if (!context.state.currentBatch) {
    return;
  }

  isSendingAnimals = true;

  let animals = context.state.currentBatch.animals;

  let animalsToSend = lodash.filter(animals, function (o) {
    return o.uploadStatus === "sending" || o.uploadStatus === "failedToSend";
  });

  //console.log(`animalsToSend.length: ${animalsToSend.length}`);

  //if no animal has an uploadStatus of sending or failedToSend then return
  if (lodash.isEmpty(animalsToSend)) {
    isSendingAnimals = false;
    return;
  }

  //get an animal that is sending or failedToSend
  //set uploadStatus to sending
  let animal = lodash.head<Animal>(animalsToSend);
  animal.uploadStatus = "sending";

  class AnimalUploadRecord {
    Uuid: String;
    BatchNo: String;
    AnimalNo: Number;
    Eartag: String = "";
    Eartag2: String = "";
    RfidTag: String = "";
    LiveMass: Number = 0;
    HookMass: Number = 0;
    GrossMass: Number = animal.mass;
    Feedlot: String = null;
    Timestamp: String;
    TagType: String;
    Barcode: String = "";
  }

  let o = new AnimalUploadRecord();
  if (animal.tagType === "dimssa") {
    //TODO: check for manual, if manual then don't fill o.RfidTag
    o.RfidTag = animal.tagId;

    let gcp = animal.tagId.slice(23, 23 + 10);

    let feedlotInfo = context.state.feedlotInfo.find((fi: FeedlotInfo) => fi.GCP === gcp);

    if (feedlotInfo) {
      let colourMode = feedlotInfo.ColorMode;

      if (colourMode === 0) {
        //multi colour
        //let colourCode = animal.tagId[36];

        let colourCodeCode = animal.tagId[36];

        let colourCode = feedlotInfo["M" + colourCodeCode];

        let colourInfo = context.state.colourInfo.find((ci: ColourInfo) => ci.Number === colourCode);
        if (colourInfo) {
          let colourAbbr = colourInfo.Abbr;
          let lastFive = animal.tagId.slice(-5);
          //let hundredThousandDigit =
          o.Eartag = colourAbbr + lastFive;
          console.log("multi colour");
          console.log("o.Eartag: " + o.Eartag);
        } else {
          console.log("no colour info found for colour number " + colourCode);
        }
      } else if (colourMode === 1) {
        //single colour
        let colourCode = feedlotInfo.M1; //same as M2, M3, ... , M6
        let colourInfo = context.state.colourInfo.find((ci: ColourInfo) => ci.Number === colourCode);
        if (colourInfo) {
          let colourAbbr = colourInfo.Abbr;
          let lastFive = animal.tagId.slice(-5);
          let hundredThousandDigit = animal.tagId[36];
          o.Eartag = colourAbbr + hundredThousandDigit + lastFive;
          console.log("single colour");
          console.log("o.Eartag: " + o.Eartag);
        } else {
          console.log("no colour info found for colour number " + colourCode);
        }
      } //TODO: What about colourMode === 2 ? (See feedlot called Suceco)
    } else {
      console.log("no feedlot info found for gcp " + gcp);
    }

    // let lastFive = animal.tagId.slice(-5);
    // //6009880037.010.130087236368
    // let hundredThousandDigit = animal.tagId[36]
    // return (hundredThousandDigit + lastFive).replace(/^0+/, ""); //remove leading 0s

    //o.Eartag must still be populated to avoid empty strings being picked up as duplicated by meat matrix
    //Check gcp of ear tag
    //Check colour mode
    //If single colour then find which single colour is being used
    //  prepend colour letter followed by 6 digits
    //If multi colour then find which colour is being used
    //  prepend colour letter followed by 5 digits (they're using the hundred thousand digit for the colour)
  }
  o.Uuid = animal.animalUuid;
  o.Barcode = animal.barcode;
  o.BatchNo = context.state.currentBatch.batchNumber;
  o.AnimalNo = animal.numberInQueue;
  if (animal.tagType === "dimssa-manual") {
    //Do lookup
    //let selectedColour = context.state.tagCapture.tagColour;
    o.Eartag = animal.tagId;
    let colourInfo = context.state.colourInfo.find((ci: ColourInfo) => ci.Number === animal.tagColourNumber);
    if (colourInfo) {
      let colourAbbr = colourInfo.Abbr;
      o.Eartag = colourAbbr + animal.tagId;
    }
    console.log(o.Eartag);

  } else if (animal.tagType !== "dimssa") {
    o.Eartag = animal.tagId;
  }
  o.LiveMass = animal.mass;
  o.GrossMass = animal.mass;
  //TODO: Fix hard coded timezone
  o.Timestamp = "/Date(" + animal.timestamp + "+0200)/";
  o.TagType = animal.tagType;

  axios.defaults.timeout = 10000;
  var headers = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json, text/plain, */*"
  };
  //axios.post("http://102.140.104.100:9000/hardwareservicelayer/api/abattoir/submitanimal", o//, {headers: headers}

  //let jsonToSend = JSON.stringify(o);
  //axios.post("http://" + configData.server.address + ":" + configData.server.portHttp + configData.restApiCalls.sendAnimalRecord, jsonToSend, {
  axios
    .post("https://" + context.state.hardwareAddress + ":" + configData.server.portHttp + configData.restApiCalls.sendAnimalRecord, o, {
      method: "post",
      headers: headers
    })
    .then(response => {
      //set icon to a single tick (pi has received data)
      //this.statusMessage = "1: " + response
      animal.uploadStatus = "receivedByServer";
      context.commit("UPDATE_ANIMAL_UPLOAD_STATUS", {
        animalUuid: animal.animalUuid,
        uploadStatus: "receivedByServer"
      });
    })
    .catch(error => {
      //set icon to cross, failed to send
      //this.statusMessage = "2: " + error
      animal.uploadStatus = "failedToSend";
      context.commit("UPDATE_ANIMAL_UPLOAD_STATUS", {
        animalUuid: animal.animalUuid,
        uploadStatus: "failedToSend"
      });
    })
    .finally(() => {
      lodash.debounce(() => sendAnimalsToHardwareServer(context), 2000)();
    });

  //attempt to send animal
  //success set to receivedByServer
  //error set to failedToSend

  // if (lodash.isEmpty(state.currentBatch.animals)) {
  //   isSendingAnimals = false;
  //   return;
  // }

  // let animalRecord = lodash
}

Vue.use(Vuex);

let dataManager = new DataManager();

export default new Vuex.Store<RootState>({
  state: {
    //dbInitialized: false,
    currentBatch: null,
    feedlotInfo: null,
    colourInfo: null,
    sgln: null,
    massCaptureEnabled: true,
    version: packageJson.version,
    allflexEnabled: true,
    orcaAddress: "orca.gigalot.systems",
    hardwareAddress: "hsl.gigalot.systems", //TODO: Environment check 127.0.0.1 local test
    lightDarkMode: "dark",
    isSingleInstance: true,
    connectedToProxy: false
  },
  mutations: {
    SET_CURRENT_BATCH(state: RootState, payload: { batch: Batch; }) {
      //payload should contain selected batch
      //TODO: consider lodash.cloneDeep
      state.currentBatch = payload.batch;
    },
    SAVE_ANIMAL(state: RootState, payload: { animal: Animal; }) {
      //assertion: currentBatch is not null, and the animal data is to be saved into currentBatch
      //add payload's animal to currentBatch, be careful re deep/shallow copy
      //TODO: consider lodash.cloneDeep
      if (state.currentBatch !== null) {
        //TODO: log or assert if batch is null
        state.currentBatch.animals.push(payload.animal);
        state.currentBatch.completedQuantity++;
      }
    },
    UPDATE_ANIMAL_UPLOAD_STATUS(state, payload) {
      //find animal in currentBatch and update
      //payload.animalUuid
      //should be uploadStatus
      //payload.uploadStatus
      if (state.currentBatch) {
        let animal = lodash.find(state.currentBatch.animals, function (o) {
          return o.animalUuid === payload.animalUuid;
        });
        if (animal) {
          animal.uploadStatus = payload.uploadStatus;
        }
      }
    },
    SET_FEEDLOT_INFO(state, payload: any) {
      state.feedlotInfo = payload;
    },
    SET_SGLN(state, payload: string) {
      state.sgln = payload;
    },
    SET_COLOUR_INFO(state, payload: any) {
      state.colourInfo = payload;
    },
    SET_MASS_CAPTURE_ENABLED(state, payload: boolean) {
      state.massCaptureEnabled = payload;
    },
    SET_ALLFLEX_ENABLED(state, payload: boolean) {
      state.allflexEnabled = payload;
    },
    SET_HARDWARE_ADDRESS(state, payload: string) {
      state.hardwareAddress = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    },
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    isSingleInstance(state, payload: boolean) {
      state.isSingleInstance = payload;
    }
  },
  actions: {
    async onAppCreated(context: any) {
      context.commit("isSingleInstance", true);
    },
    setCurrentBatch(context: any, batch: Batch) {
      context.commit("SET_CURRENT_BATCH", { batch: batch });
      if (batch && context.state.tagCapture.tagType === "no-tag") {
        //if current tag is type no tag then set NO TAG X where X is number of no tags + 1
        let numNoTags = batch.animals.filter(animal => animal.tagType === "no-tag").length;
        context.commit("tagCapture/SET_TAG_ID", `NO TAG ${numNoTags + 1}`);
      }
    },
    saveAnimal(context, animal: Animal) {
      context.commit("SAVE_ANIMAL", { animal: animal });
      if (!isSendingAnimals) sendAnimalsToHardwareServer(context);
    },
    restoreSession(context) {
      if (!isSendingAnimals) {
        sendAnimalsToHardwareServer(context);
      }

      context.dispatch("tagCapture/restoreSession");
    },
    updateAnimal(context, payload) {
      context.commit("UPDATE_ANIMAL", payload);
    }
  },
  getters: {
    feedlot: (state: RootState, getters) => {
      if (!state.feedlotInfo) return null;
      if (!getters.gcpCode) return null;

      let feedlot = lodash.find(state.feedlotInfo, f => {
        return f.GCP === getters.gcpCode;
      });

      return feedlot;
    },
    gcpCode: (state: RootState) => {
      return state.sgln ? state.sgln.split(".")[1] : null;
    },
    companyName: (state: RootState, getters: any) => {
      let feedlot = getters.feedlot;
      return feedlot ? feedlot.Name : "Not found";
    },
    siteName: (state: RootState) => {
      return "NA";
    },
    colourMode: (state: RootState, getters: any) => {
      let feedlot = getters.feedlot;
      return feedlot ? feedlot.ColorMode : null;
    },
    getTagColour: (state: RootState) => {
      return (sgtin: any) => getTagColour(sgtin, state.feedlotInfo, state.colourInfo);
    },
    getTagFeedlot: (state: RootState) => {
      return (sgtin: any) => getTagFeedlot(sgtin, state.feedlotInfo);
    },
    getCurrentTagFeedlot: (state: any, getters: any) => {
      return getters["getTagFeedlot"](state.tagCapture.tagId);
    },
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    }
  },
  modules: {
    tagCapture,
    config,
    popup
  },
  plugins: [vuexLocal.plugin]
});
