import Vue from "vue";
import vuetify from './plugins/vuetify';
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./registerServiceWorker";
import ReconnectingWebSocket from "reconnecting-websocket";

Vue.config.devtools = true;
Vue.config.productionTip = false;

const opts = {
  theme: { disable: true }
};

store.commit("version");

const webSocketConnectedToProxy = new ReconnectingWebSocket(`wss://${store.state.hardwareAddress}:9000/webSocketConnectedToProxy`);
webSocketConnectedToProxy.addEventListener("open", event => {
  console.log("webSocketConnectedToProxy open");
  if (store.state.connectedToProxy !== true) store.commit("connectedToProxy", true);
  startMessageInterval();
 // store.dispatch("sync/onConnectedToProxy");
});
webSocketConnectedToProxy.addEventListener("message", event => {
  console.log("webSocketConnectedToProxy message: ", event.data);
  const numInstances = parseInt(event.data);
  store.commit("isSingleInstance", numInstances === 1);
});

webSocketConnectedToProxy.addEventListener("close", event => {
  console.log("webSocketConnectedToProxy close");
  if (store.state.connectedToProxy !== false) store.commit("connectedToProxy", false);
  stopMessageInterval();
});

let t: number | undefined;

function startMessageInterval() {
  if (t) return;
  t = setInterval(() => {
    try {
      webSocketConnectedToProxy.send("x");
    } catch (err) {
      console.error("failed to send heartbeat message:", err);
    }
  }, 10 * 1000);
}

function stopMessageInterval() {
  clearInterval(t);
  t = undefined;
}

new Vue({
  vuetify,
  router,
  el: "#app",
  store,
  render: h => h(App)
});
