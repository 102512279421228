//contains settings that are used by all configurations of this app. This should not be confused with the store's config module which will handle different configurations for different user needs
//this should be renamed

class RestApiCalls {
  readonly rfidReaderScan: string = "/HardwareServiceLayer/api/Rfid/sgtin";
  readonly sgln: string = "/HardwareServiceLayer/api/Rfid/sgln";
  readonly getBatches: string = "/HardwareServiceLayer/api/Abattoir/Batches";
  readonly hardwareStatus: string = "/HardwareServiceLayer/api/Hardware/Status";
  readonly sendAnimalRecord: string = "/HardwareServiceLayer/api/Abattoir/SubmitAnimal";
  readonly checkReceived3rdParty: string = "/HardwareServiceLayer/api/Abattoir/UploadQueue";
  readonly isSlaughtered: string = "/HardwareServiceLayer/api/Abattoir/IsSlaughtered";
  readonly D2Barcode: string = "/HardwareServiceLayer/api/2DBarcode";
  readonly feedlots: string = "https://gigalot.systems/shared/Feedlots";
  readonly colours: string = "https://gigalot.systems/shared/Colors";
}

class Server {
  //readonly address: string = "192.168.11.111";
  //readonly address: string = "127.0.0.1"; go to store.ts for hardwareAddress
  readonly portHttp: string = "9000"; //9004 is http
  readonly portScaleWebSocket: string = "8002"; //8006 is ws
  readonly portAllflexWebSocket: string = "8003"; //8007 is ws
  readonly portOrcaWebSocket: string = "8004"; //8008 is ws
  readonly portPipeReader: string = "8005"; //8009 is ws
}

class Config {
  readonly restApiCalls: RestApiCalls = new RestApiCalls();
  readonly server: Server = new Server();
}

export const configData: Config = new Config();
